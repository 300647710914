// Core
import React, { useState } from 'react'
// BootStrap
import { Button } from 'react-bootstrap';
// Media
import IamWorker from '../assets/images/im-worker.svg';
import IamWorkerWhite from '../assets/images/im-worker-white.svg';
import IamCreator from '../assets/images/im-creator.svg';
import IamCreatorWhite from '../assets/images/im-creator-white.svg';

const SliderContent = (props) => {
    const [hoverWorker, setHoverWorker] = useState(IamWorker);
    const [hoverCreator, setHoverCreator] = useState(IamCreator);

    const handleWorkerHover = () => {
        setHoverWorker(IamWorkerWhite);
    }
    const handleWorkerLeave = () => {
        setHoverWorker(IamWorker);
    }
    const handleCreatorHover = () => {
        setHoverCreator(IamCreatorWhite);
    }
    const handleCreatorLeave = () => {
        setHoverCreator(IamCreator);
    }
    return (
        <div>
            <p>{props.description}</p>
            <a href="https://my.hyreshift.com" target="_blank" rel="noreferrer">
                <Button variant="outline-primary" className="btn-intro" onMouseEnter={handleWorkerHover} onMouseLeave={handleWorkerLeave}>
                    <img src={hoverWorker} alt="icon" className="mr-2" />Worker Registration
                </Button>
            </a>
            <a href="https://mybiz.hyreshift.com" target="_blank" rel="noreferrer">
                <Button variant="outline-primary" className="margin-left btn-intro" onMouseEnter={handleCreatorHover} onMouseLeave={handleCreatorLeave}>
                    <img src={hoverCreator} alt="icon" className="mr-2" />Company Registration
                </Button>
            </a>
        </div>
    )
}

export default SliderContent
