// Core
import React, { useEffect, useState } from 'react'
// BootStrap
import { Container } from 'react-bootstrap';

import axios from "axios";
import config from '../../config';

const Creator = () => {
    const [content, setContent] = useState('')

    const getContent = () => {
        const data = {
            data: {
                type: 'HIWC'
            }
        }
        const url = config.url + "/public/cms";
        axios.post(url, data).then(response => {
            if (response.data.status === true) {
                setContent(response.data.data.content)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getContent()
    }, [])

    return (
        <div className="tandc">
            <div className="breadcumb">
                <h1>How It Works - Creator</h1>
            </div>
            <Container>
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </Container>
        </div>
    )
}

export default Creator
