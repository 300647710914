// Core
import React, { useEffect, useState } from 'react'
// BootStrap
import { Container, Col, Row } from 'react-bootstrap';
// Library
import axios from 'axios'
import config from '../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import Chip from '@material-ui/core/Chip';
// Media
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { faMapMarkerAlt, faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";

import {
    SimpleDateFormat,
    timeformat,
    shortJobDescription
} from "../functions";

const ListJobs = () => {

    const [latestJobs, setLatestJobs] = useState([])
    const getLatestJobs = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        const url = config.url + '/public/cms/gigs'
        axios.get(url, headerConfig).then(response => {
            setLatestJobs(response.data.data)
        })
    }

    useEffect(() => {
        getLatestJobs()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className="breadcumb">
                <h1>List Jobs</h1>
            </div>
            <section id="opening" className="mb-5">
                <Container>
                    <Row>
                        {latestJobs.map(latestJob => {
                            return (
                                <Col md={4} xs={12} key={latestJob.id}>
                                    <div className="card-job">
                                        <Chip label={latestJob.industry} className="badge-category" />
                                        <h3>{latestJob.headline}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: shortJobDescription(latestJob.description) }} />
                                        <div className="info">
                                            <p><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3" />{latestJob.location}, {latestJob.city}, {latestJob.state}, {latestJob.zip}</p>
                                            <p><FontAwesomeIcon icon={faCalendarAlt} className="mr-3" />{SimpleDateFormat(latestJob.start_time)} - {SimpleDateFormat(latestJob.end_time)}</p>
                                            <p><FontAwesomeIcon icon={faClock} className="mr-3" />{timeformat(latestJob.break_start)} - {timeformat(latestJob.break_end)}</p>
                                        </div>
                                        <Row className="price">
                                            <Col md={6} xs={6}>
                                                <p>${latestJob.rate} / hour</p>
                                            </Col>
                                            <Col md={6} xs={6} className="text-right">
                                                <a href="https://my.hyreshift.com" target="_blank" rel="noreferrer">More Details <ArrowForwardIcon fontSize="small" /></a>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default ListJobs
