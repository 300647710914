// Core
import React, { useEffect, useState } from 'react'
// BootStrap
import { Button, Container, Col, Row } from 'react-bootstrap';
// Library
import axios from 'axios'
import config from '../config'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Media
import Logo from '../assets/images/logo/logo-footer.svg';
import { faInstagram, faFacebookF, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";
// Components
// import HowItWorks from './HowItWorks'
import ContactModel from './ContactModel'

const Footer = () => {
    // const [open, setOpen] = useState(false)
    const [openHelp, setOpenHelp] = useState(false)
    const [shortDescription, setShortDescription] = useState('')
    const [instaLink, setInstaLink] = useState('')
    const [fbLink, setFbLink] = useState('')
    const [linkedInLink, setLinkedInLink] = useState('')
    const [twitterLink, setTwitterLink] = useState('')
    
    const getFooterContent = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        const url = config.url + '/public/cms/footer'
        axios.get(url, headerConfig).then(response => {
            const data = response.data.data
            setShortDescription(data.footer.sub_heading)
            setInstaLink(data.footer.ig)
            setFbLink(data.footer.fb)
            setLinkedInLink(data.footer.li)
            setTwitterLink(data.footer.tt)
        })
    }

    useEffect(() => {
        getFooterContent()
        // eslint-disable-next-line
    }, [])

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleOpenHelp = () => {
        setOpenHelp(true);
    };

    const handleCloseHelp = () => {
        setOpenHelp(false);
    };
    return (
        <div>
            {/* <HowItWorks open={open} close={handleClose} /> */}
            <ContactModel open={openHelp} close={handleCloseHelp} />
            <div id="footer">
                <Container>
                    <Row>
                        <Col xs={12} md={3} className="my-auto">
                            <div className="text-center">
                                <img src={Logo} alt="Logo" className="img-fluid w-75" />
                            </div>
                            <p>{shortDescription}</p>
                        </Col>
                        <Col xs={6} md={3}>
                            <h5>Important Links</h5>
                            <ul>
                                <li><a href="./"><h6>Home</h6></a></li>
                                <li><a href="/list-jobs"><h6>Jobs</h6></a></li>
                                <li><a href="/how-it-works/creator"><h6>How it works - Creator</h6></a></li>
                                <li><a href="/how-it-works/worker"><h6>How it works - Worker</h6></a></li>
                                {/* <li>
                                    <a href="#!" onClick={e => {
                                        e.preventDefault()
                                        handleOpen()
                                    }}>
                                        <h6>How it works</h6>
                                    </a>
                                </li> */}
                                <li><a href="https://my.hyreshift.com" target="_blank" rel="noreferrer"><h6>Login</h6></a></li>
                                <li><a href="https://my.hyreshift.com/#/create-account" target="_blank" rel="noreferrer"><h6>Register</h6></a></li>
                            </ul>
                        </Col>
                        <Col xs={6} md={2}>
                            <h5>Quick Links</h5>
                            <ul>
                                <li><a href="/support"><h6>Support</h6></a></li>
                                <li><a href="/terms-and-conditions"><h6>Terms & Conditions</h6></a></li>
                                <li><a href="/privacy-policy"><h6>Privacy Policy</h6></a></li>
                                <li>
                                    <a href="#!" onClick={e => {
                                        e.preventDefault()
                                        handleOpenHelp()
                                    }}>
                                        <h6>Contact Us</h6>
                                    </a>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={12} md={4} className="m-center">
                            <h5>Social Links</h5>
                            {instaLink !== '#!' ? <a href={instaLink} rel="noreferrer" target="_blank"><Button className="btn-social"><FontAwesomeIcon icon={faInstagram} /></Button></a> : '' }
                            {fbLink !== '#!' ? <a href={fbLink} rel="noreferrer" target="_blank"><Button className="btn-social"><FontAwesomeIcon icon={faFacebookF} /></Button></a> : '' }
                            {linkedInLink !== '#!' ? <a href={linkedInLink} rel="noreferrer" target="_blank"><Button className="btn-social"><FontAwesomeIcon icon={faLinkedinIn} /></Button></a> : '' }
                            {twitterLink !== '#!' ? <a href={twitterLink} rel="noreferrer" target="_blank"><Button className="btn-social"><FontAwesomeIcon icon={faTwitter} /></Button></a> : ''}
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Footer
