// Core
import React, { useEffect, useState } from 'react'
// BootStrap
import { Button, Container, Col, Row, Form } from 'react-bootstrap';
// Library
import axios from 'axios'
import config from '../config'
import Image from 'material-ui-image';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// Components
import SliderContent from '../components/SliderContent';
import Chip from '@material-ui/core/Chip';
import Testimonials from '../components/Testimonials';
// Media
import ImageLeft from '../assets/images/bg-form-search-left.svg';
import ImageRight from '../assets/images/bg-form-search-right.svg';
import ImageBottom from '../assets/images/bg-search-form-bottom.svg';
import ArrowRight from '../assets/images/arrow-right.svg';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowRightWhite from '../assets/images/arrow-right-white.svg';
import GraphicsRegister from '../assets/images/graphics-register.svg';
import TestimonialsBottom from '../assets/images/testimonials-bottom.svg';
import { faMapMarkerAlt, faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";

import {
    SimpleDateFormat,
    timeformat,
    shortJobDescription
} from "../functions";

import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix")
    .map(icon => Icons[icon])

library.add(...iconList)

const Homepage = () => {
    const [h1Heading, setH1Heading] = useState('')
    const [h1Description, setH1Description] = useState('')
    const [image, setImage] = useState('')
    const [searchHeading, setSearchHeading] = useState('')
    const [registerHeading, setRegisterHeading] = useState('')

    const getHomeContent = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        const url = config.url + '/public/cms/home'
        axios.get(url, headerConfig).then(response => {
            const data = response.data.data
            setH1Heading(data.main.heading)
            setH1Description(data.main.sub_heading)
            setImage(data.main.image)
            setSearchHeading(data.search.heading)
            setRegisterHeading(data.register.heading)
        })
    }

    useEffect(() => {
        getHomeContent()
        getLatestJobs()
        getTopCategories()
        handleSkillsChange()
        handleLocationsChange()
        // eslint-disable-next-line
    }, [])

    const [skills, setSkills] = useState([])
    const handleSkillsChange = (e, value) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        let url;
        if (value) {
            if (value !== '') {
                url = config.url + '/public/cms/skills?text=' + value
            }
        } else {
            url = config.url + '/public/cms/skills?text=all'
        }
        axios.get(url, headerConfig).then(response => {
            setSkills(response.data.data)
        })
    }

    const [locations, setLocations] = useState([])
    const handleLocationsChange = (e, value) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        let url;
        if (value) {
            if (value !== '') {
                url = config.url + '/public/cms/locations?text=' + value
            }
        } else {
            url = config.url + '/public/cms/locations?text=all'
        }
        axios.get(url, headerConfig).then(response => {
            setLocations(response.data.data)
        })
    }

    const [latestJobs, setLatestJobs] = useState([])
    const getLatestJobs = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        const url = config.url + '/public/cms/gigs'
        axios.get(url, headerConfig).then(response => {
            setLatestJobs(response.data.data)
        })
    }

    const [topCategories, setTopCategories] = useState([])
    const [categoriesCount, setCategoriesCount] = useState([])
    const getTopCategories = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        const url = config.url + '/public/cms/categories?type=top'
        axios.get(url, headerConfig).then(response => {
            setCategoriesCount(response.data.data.count)
            setTopCategories(response.data.data.industries)
        })
    }

    return (
        <div>
            <section id="intro">
                <Container className="section">
                    <Row>
                        <Col xs={6} className="my-auto">
                            <h1>{h1Heading}</h1>
                            <div className="web-view">
                                <SliderContent description={h1Description} />
                            </div>
                        </Col>
                        <Col xs={6}>
                            <Image
                                src={image}
                                aspectRatio={(1.44 / 1)}
                                loading={<CircularProgress style={{ color: "#F94D1D" }} />}
                            />
                        </Col>
                        <Col xs={12} className="mobile-view">
                            <SliderContent description={h1Description} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="search">
                <div className="bg-search">
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md={8} className="text-center">
                                <h2>{searchHeading}</h2>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md={3} className="web-view image-left">
                                <img src={ImageLeft} alt="left" className="img-fluid" />
                            </Col>
                            <Col md={5}>
                                <Form className="mt-3 card-form-search">
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                fullWidth
                                                options={skills}
                                                getOptionLabel={(option) => option.name}
                                                onInputChange={handleSkillsChange}
                                                renderInput={(params) => <TextField {...params} label="Skill" variant="outlined" />}
                                            />
                                        </Col>
                                        <Col md={12} xs={12}>
                                            &nbsp;
                                        </Col>
                                        <Col md={12} xs={12}>
                                            &nbsp;
                                        </Col>
                                        <Col md={12} xs={12}>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                fullWidth
                                                options={locations}
                                                getOptionLabel={(option) => option.name}
                                                onInputChange={handleLocationsChange}
                                                renderInput={(params) => <TextField {...params} label="Location" variant="outlined" />}
                                            />
                                        </Col>
                                        <Col md={12} xs={12} className="mt-5">
                                            <a href="https://my.hyreshift.com" target="_blank" rel="noreferrer">
                                                <Button variant="primary" className="w-100">Search</Button>
                                            </a>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                            <Col md={3} className="web-view image-right">
                                <img src={ImageRight} alt="right" className="img-fluid h-82" />
                            </Col>
                        </Row>
                    </Container>
                    <div className="bottom">
                        <img src={ImageBottom} alt="ImageBottom" className="img-fluid" />
                    </div>
                </div>
            </section>
            <section>
                <Container className="heading text-center">
                    <h2>Explore <span>Categories</span></h2>
                    <p>Find Jobs From Various Domains</p>
                </Container>
                <Container>
                    <Row>
                        {topCategories.map(topCategory => {
                            return (
                                <Col md={3} xs={12} key={topCategory.id}>
                                    <div className="card-categories">
                                        {/* <Button className="btn-icon"><FontAwesomeIcon icon={topCategory.icon} /></Button> */}
                                        <Button className="btn-icon">{topCategory.icon}</Button>
                                        <h3>{topCategory.name}</h3>
                                        {(topCategory.openings === 0) ? (<p>No Openings</p>) : (<p>{topCategory.openings} New Openings</p>)}
                                        {(topCategory.openings === 0) ? ('') : (<a href="https://my.hyreshift.com" target="_blank" rel="noreferrer">View all openings <img src={ArrowRight} alt="View All" className="ml-3" /></a>)}
                                    </div>
                                </Col>
                            )
                        })}
                        <Col md={3} xs={12}>
                            <a href="/all-categories">
                                <div className="card-categories text-center">
                                    <h3 className="color-primary pt-3 pb-3">{categoriesCount} <br /> Categories to explore</h3>
                                </div>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section id="opening">
                <Container className="heading text-center">
                    <h2>Latest <span>Job Openings</span></h2>
                    <p>Job Openings in Various Fields</p>
                </Container>
                <Container>
                    <Row>
                        {latestJobs.map(latestJob => {
                            return (
                                <Col md={4} xs={12} key={latestJob.id}>
                                    <div className="card-job">
                                        <Chip label={latestJob.industry} className="badge-category" />
                                        <h3>{latestJob.headline}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: shortJobDescription(latestJob.description) }} />
                                        <div className="info">
                                            <p><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3" />{latestJob.location}, {latestJob.city}, {latestJob.state}, {latestJob.zip}</p>
                                            <p><FontAwesomeIcon icon={faCalendarAlt} className="mr-3" />{SimpleDateFormat(latestJob.start_time)} - {SimpleDateFormat(latestJob.end_time)}</p>
                                            <p><FontAwesomeIcon icon={faClock} className="mr-3" />{timeformat(latestJob.break_start)} - {timeformat(latestJob.break_end)}</p>
                                        </div>
                                        <Row className="price">
                                            <Col md={6} xs={6}>
                                                <p>${latestJob.rate} / hour</p>
                                            </Col>
                                            <Col md={6} xs={6} className="text-right">
                                                <a href="https://my.hyreshift.com" target="_blank" rel="noreferrer">More Details <ArrowForwardIcon fontSize="small" /></a>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                    <div className="text-center">
                        <a href="https://my.hyreshift.com" target="_blank" rel="noreferrer">
                            <Button variant="primary" className="mt-5 mb-5">Explore More Jobs <img src={ArrowRightWhite} alt="Explore More Jobs" className="ml-2" /></Button>
                        </a>
                    </div>
                </Container>
            </section>
            <section id="register">
                <div className="bg-register">
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md={4}>
                                <img src={GraphicsRegister} alt="Graphics Register" className="img-fluid" />
                            </Col>
                            <Col md={8} className="my-auto">
                                <div className="text-center">
                                    <h2>{registerHeading}</h2>
                                    <a href="https://my.hyreshift.com/#/create-account" target="_blank" rel="noreferrer">
                                        <Button variant="primary" className="mt-3 mb-5">Register Now <img src={ArrowRightWhite} alt="Register Now" className="ml-2" /></Button>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
            <section id="testimonials">
                <div className="bg-testimonials">
                    <Container className="heading-2">
                        <p>Client Testimonials</p>
                        <h2>What a Job Holder <br /> Says <span>About Us</span></h2>
                    </Container>
                    <Container>
                        <Row className="justify-content-md-center">
                            <Col md={10}>
                                <Testimonials />
                            </Col>
                        </Row>
                    </Container>
                    <div className="web-view bottom">
                        <img src={TestimonialsBottom} alt="ImageBottom" className="img-fluid" />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Homepage
