// Core
import React, { useEffect, useState } from 'react'
// BootStrap
import { Container, Col, Row } from 'react-bootstrap';
// Library
import axios from 'axios'
import config from '../config'
// Media
import ArrowRight from '../assets/images/arrow-right.svg';

const AllCategories = () => {

    useEffect(() => {
        getTopCategories()
        // eslint-disable-next-line
    }, [])

    const [topCategories, setTopCategories] = useState([])
    const getTopCategories = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        const url = config.url + '/public/cms/categories?type=all'
        axios.get(url, headerConfig).then(response => {
            setTopCategories(response.data.data.industries)
        })
    }

    return (
        <div>
            <div className="breadcumb">
                <h1>Explore Categories</h1>
            </div>
            <section>
                <Container>
                    <Row className="mt-4 mb-4">
                        {topCategories.map(topCategory => {
                            return (
                                <Col md={3} xs={12} key={topCategory.id} className="d-table mt-4 mb-4">
                                    <div className="card-categories d-table-cell">
                                        <h3 className="inner">{topCategory.name}</h3>
                                        {(topCategory.openings === 0) ? (<p>No Openings</p>) : (<p>{topCategory.openings} New Openings</p>)}
                                        {(topCategory.openings === 0) ? ('') : (<a href="https://my.hyreshift.com" target="_blank" rel="noreferrer">View all openings <img src={ArrowRight} alt="View All" className="ml-3" /></a>)}
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default AllCategories
