// Core
import React, { useEffect, useState } from 'react'
// Library
import axios from 'axios'
import config from '../config'
import Rating from '@material-ui/lab/Rating';
import Carousel from 'react-material-ui-carousel'

const Testimonials = () => {

    useEffect(() => {
        getTestimonials()
        // eslint-disable-next-line
    }, [])

    const [testimonials, setTestimonials] = useState([])
    const getTestimonials = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        const url = config.url + '/public/cms/testimonials'
        axios.get(url, headerConfig).then(response => {
            setTestimonials(response.data.data)
        })
    }

    return (
        <div className="testimonials text-center">
            <Carousel
                indicators={false}
                animation="slide"
                navButtonsAlwaysVisible={true}
            >
                {testimonials.map((content, index) =>
                    <div key={index}>
                        <img src={content.image} alt={content.name} className="testimonials-profile-picture" /><br />
                        <Rating value={content.rating} precision={0.5} readOnly />
                        <p>{content.comment}</p>
                        <h3>{content.name}</h3>
                    </div>
                )}
            </Carousel>
        </div>
    )
}

export default Testimonials
