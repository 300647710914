// Core
import React, { useEffect, useState } from 'react'
// BootStrap
import { Container, Col, Row } from 'react-bootstrap';
// Library
import axios from 'axios'
import config from '../config'
import { Backdrop, Fade, Modal } from '@material-ui/core';
// Media
import Email from '../assets/images/email.svg'
import Call from '../assets/images/call.svg'
import CloseIcon from '@material-ui/icons/Close';

const ContactModel = (props) => {
    const open = props.open;
    const [openHelp, setOpenHelp] = useState(false)

    useEffect(() => {
        if (open) {
            handleOpenHelp()
            if (props.side) {
                props.side()
            }
            getContactContent()
        }
        // eslint-disable-next-line
    }, [open])

    const handleOpenHelp = () => {
        setOpenHelp(true);
    };

    const handleCloseHelp = () => {
        setOpenHelp(false);
        props.close()
    };

    const [description, setDescription] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')

    const getContactContent = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        const url = config.url + '/public/cms/help'
        axios.get(url, headerConfig).then(response => {
            const data = response.data.data
            setDescription(data.help.sub_heading)
            setEmail(data.help.email)
            setNumber(data.help.number)
        })
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openHelp}
                onClose={handleCloseHelp}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openHelp}>
                    <Container className="card-contact-model">
                        <div className="text-right">
                            <CloseIcon className="close-icon" onClick={handleCloseHelp} />
                        </div>
                        <h2>Contact Us 24/7</h2>
                        <p>{description}</p>
                        <Row className="justify-content-center">
                            <Col md={4} xs={6}>
                                <a href={"mailto:" + email}>
                                    <div className="card-contact-inner">
                                        <img src={Email} alt="Email" className="img-fluid" />
                                        <h3>Email</h3>
                                        <p>{email}</p>
                                    </div>
                                </a>
                            </Col>
                            <Col md={4} xs={6}>
                                <a href={"tel:" + number}>
                                    <div className="card-contact-inner">
                                        <img src={Call} alt="Call" className="img-fluid" />
                                        <h3>Call</h3>
                                        <p>{number}</p>
                                    </div>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </Fade>
            </Modal>
        </div>
    )
}

export default ContactModel
