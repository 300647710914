import moment from "moment";

export function SimpleDateFormat(data) {
    var d = moment(data, 'YYYY-MM-DD hh:mm:ss');
    return d.format('MM/DD/YYYY')
}

export function timeformat(cell) {
    if (cell === "") {

    } else {
        var d = moment(cell, 'hh-mm-ss');
        return d.format('hh:mm A')
    }
}

export function shortJobDescription(value) {
    if (value.length > 50) {
        var str = value.substring(0, 50) + '...'
        return str
    } else {
        return value
    }
}
