// Core
import React, { useEffect, useState } from 'react'
// BootStrap
import { Button, Badge, Container, Col, Row, InputGroup, FormControl } from 'react-bootstrap';
// Library
import axios from 'axios'
import moment from 'moment'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from 'material-ui-image';
// Media
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
// Other
import config from '../../config'
import CircularProgress from '@material-ui/core/CircularProgress';

const BlogSingle = (props) => {
    const slug = props.match.params.slug
    const [recentPosts, setRecentPosts] = useState([])
    const [categories, setCategories] = useState([])
    const [loader, setLoader] = useState('')
    const [featuredImage, setFeaturedImage] = useState("")
    const [title, setTitle] = useState("")
    const [date, setDate] = useState("")
    const [categoryNames, setCategoryNames] = useState([{ id: '', name: '' }])
    const [content, setContent] = useState("")
    const [author, setAuthor] = useState("")
    const [text, setText] = useState('')

    useEffect(() => {
        getPost()
        getRecentPosts()
        getCategories()
        // eslint-disable-next-line
    }, [])

    function changeFormate(date) {
        const mDate = moment(date, 'yyyy-MM-DD')
        return mDate.format('DD MMM yyyy')
    }

    const search = () => {
        const url = '/blog/search/' + text
        props.history.push(url)
    }

    const handelTextChange = (e) => {
        const value = e.target.value
        setText(value)
    }

    const getPost = () => {
        const url = config.url + '/public/blogs/' + slug
        axios.get(url).then(response => {
            setFeaturedImage(response.data.image)
            setTitle(response.data.title)
            setDate(response.data.date)
            setCategoryNames(response.data.categories)
            setContent(response.data.content)
            setAuthor(response.data.author)
            setLoader('d-none')
        }).catch(error => {
            console.log(error)
        })
    }

    const getRecentPosts = () => {
        const url = config.url + '/public/blogs/recent'
        axios.get(url).then(response => {
            setRecentPosts(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const getCategories = () => {
        const url = config.url + '/public/blogs/top-categories'
        axios.get(url).then(response => {
            console.log(response.data)
            setCategories(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <div>
            <div className="breadcumb">
                <Container>
                    <h1>{title}</h1>
                </Container>
            </div>
            <Container>
                <Row>
                    <Col md={8}>
                        <div className="text-center">
                            <CircularProgress className={loader} style={{ color: "#F94D1D" }} />
                        </div>
                        <div className="blog mb-5">
                            <Image
                                src={featuredImage}
                                aspectRatio={(1.89 / 1)}
                                loading={<CircularProgress style={{ color: "#F94D1D" }} />}
                                alt={title}
                            />
                            <div className="content">
                                {categoryNames.map((category, index) => {
                                    return (
                                        <Badge variant="primary" className="mr-2">{category.name}</Badge>
                                    )
                                })}
                                <p dangerouslySetInnerHTML={{ __html: content }} />
                                <hr />
                                <Row className="meta">
                                    <Col md={6}>
                                        <h4><AccountCircleIcon fontSize="small" /> {author}</h4>
                                    </Col>
                                    <Col md={6} className="text-right">
                                        <p><QueryBuilderIcon fontSize="small" /> {changeFormate(date)}</p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} className="blog-sidebar">
                        <InputGroup>
                            <FormControl
                                placeholder="Search Here"
                                aria-label="Search Here"
                                aria-describedby="basic-addon2"
                                onChange={handelTextChange}
                            />
                            <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={search}><FontAwesomeIcon icon={faSearch} /></Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <div className="text-center">
                            <CircularProgress className={loader} style={{ color: "#F94D1D" }} />
                        </div>
                        <h4>Recent Posts</h4>
                        <div className="recent">
                            {recentPosts.map(recentPost => {
                                return (
                                    <Row key={recentPost.id}>
                                        <Col md={3} xs={3}>
                                            <Image
                                                src={recentPost.image}
                                                aspectRatio={(1 / 1)}
                                                loading={<CircularProgress style={{ color: "#F94D1D" }} />}
                                                alt={recentPost.title}
                                            />
                                        </Col>
                                        <Col md={9} xs={9} className="my-auto">
                                            <a href={"/blog/" + recentPost.slug}><h4>{recentPost.title}</h4></a>
                                        </Col>
                                        <Col md={12}>
                                            <hr />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                        <h4 className="mb-4 mt-5">Categories</h4>
                        <div className="text-center">
                            <CircularProgress className={loader} style={{ color: "#F94D1D" }} />
                        </div>
                        <div className="categories">
                            {categories.map(category => {
                                return (
                                    <div key={category.id}>
                                        <a href={"/category/" + category.slug}><h4>{category.name} [ {category.count} ]</h4></a>
                                        <hr />
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default BlogSingle