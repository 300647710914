// Core
import React, { useEffect, useState } from 'react'
// BootStrap
import { Button, Container, Col, Row, InputGroup, FormControl } from 'react-bootstrap';
// Library
import axios from 'axios'
import moment from 'moment'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfiniteScroll from 'react-infinite-scroll-component';
import Image from 'material-ui-image';
// Media
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
// Other
import config from '../../config'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Chip } from '@material-ui/core';

const BlogSearch = (props) => {
    const slug = props.match.params.slug
    const [posts, setPosts] = useState([])
    const [recentPosts, setRecentPosts] = useState([])
    const [categories, setCategories] = useState([])
    const [loader, setLoader] = useState('')
    const [text, setText] = useState('')
    const [start, setStart] = useState(0)
    const limit = 6

    useEffect(() => {
        getPostsBySearch(start, limit)
        getCategories()
        getRecentPosts()
        // eslint-disable-next-line
    }, [])

    function changeFormate(date) {
        const mDate = moment(date, 'yyyy-MM-DD')
        return mDate.format('DD MMM yyyy')
    }

    const search = () => {
        const url = '/blog/search/' + text
        props.history.push(url)
        getPostsBySearch(start, limit)
    }

    const handelTextChange = (e) => {
        const value = e.target.value
        setText(value)
    }

    const getPostsBySearch = (start, limit) => {
        const params = {
            start,
            limit,
            search: slug
        }
        const headerConfig = {
            params
        }
        const url = config.url + '/public/blogs'
        axios.get(url, headerConfig).then(response => {
            setPosts([...posts, ...response.data])
            setLoader('d-none')
        }).catch(error => {
            console.log(error)
        })
    }

    const getRecentPosts = () => {
        const url = config.url + '/public/blogs/recent'
        axios.get(url).then(response => {
            setRecentPosts(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    const getCategories = () => {
        const url = config.url + '/public/blogs/top-categories'
        axios.get(url).then(response => {
            setCategories(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    return (
        <div>
            <div className="breadcumb">
                <h1>Blogs</h1>
            </div>
            <Container>
                <Row>
                    <Col md={8}>
                        <div className="text-center">
                            <CircularProgress className={loader} style={{ color: "#F94D1D" }} />
                        </div>
                        <InfiniteScroll
                            dataLength={posts.length}
                            next={() => {
                                var _start = start
                                _start += limit
                                getPostsBySearch(_start, limit)
                                setStart(_start)
                            }}
                            hasMore={true}
                        >
                            {posts.map(post => {
                                return (
                                    <div className="blog mb-5" key={post.id}>
                                        <Image
                                            src={post.image}
                                            aspectRatio={(1.89 / 1)}
                                            loading={<CircularProgress style={{ color: "#F94D1D" }} />}
                                            alt={post.title}
                                        />
                                        <div className="content">
                                            {post.categories.map(category => {
                                                return (
                                                    <Chip label={category.name} key={category.id} className="badge" />
                                                )
                                            })}
                                            <a href={"/blog/" + post.slug}><h2>{post.title}</h2></a>
                                            <p dangerouslySetInnerHTML={{ __html: post.content + '...' }} />
                                            <hr />
                                            <Row className="meta">
                                                <Col md={6}>
                                                    <h4><AccountCircleIcon fontSize="small" /> {post.author}</h4>
                                                </Col>
                                                <Col md={6} className="text-right">
                                                    <p><QueryBuilderIcon fontSize="small" /> {changeFormate(post.date)}</p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                )
                            })}
                        </InfiniteScroll>
                    </Col>
                    <Col md={4} className="blog-sidebar">
                        <InputGroup>
                            <FormControl
                                placeholder="Search Here"
                                aria-label="Search Here"
                                aria-describedby="basic-addon2"
                                onChange={handelTextChange}
                            />
                            <InputGroup.Append>
                                <Button variant="outline-secondary" onClick={search}><FontAwesomeIcon icon={faSearch} /></Button>
                            </InputGroup.Append>
                        </InputGroup>
                        <div className="text-center">
                            <CircularProgress className={loader} style={{ color: "#F94D1D" }} />
                        </div>
                        <h4>Recent Posts</h4>
                        <div className="recent">
                            {recentPosts.map(recentPost => {
                                return (
                                    <Row key={recentPost.id}>
                                        <Col md={3} xs={3}>
                                            <Image
                                                src={recentPost.image}
                                                aspectRatio={(1 / 1)}
                                                loading={<CircularProgress style={{ color: "#F94D1D" }} />}
                                                alt={recentPost.title}
                                            />
                                        </Col>
                                        <Col md={9} xs={9} className="my-auto">
                                            <a href={"/blog/" + recentPost.slug}><h4>{recentPost.title}</h4></a>
                                        </Col>
                                        <Col md={12}>
                                            <hr />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </div>
                        <h4 className="mb-4 mt-5">Categories</h4>
                        <div className="text-center">
                            <CircularProgress className={loader} style={{ color: "#F94D1D" }} />
                        </div>
                        <div className="categories">
                            {categories.map(category => {
                                return (
                                    <div key={category.id}>
                                        <a href={"/category/" + category.slug}><h4>{category.name} [ {category.count} ]</h4></a>
                                        <hr />
                                    </div>
                                )
                            })}
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}

export default BlogSearch