import './App.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import Layout from './Layout'
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return (
        <div>
            <Layout />
        </div>
    );
}

export default App;
