// Core
import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx';
import { useLocation } from "react-router-dom";
// BootStrap
import { Button, Container, Navbar, Nav } from 'react-bootstrap';
// Library
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, Divider, makeStyles, List, ListItem, ListItemIcon, ListSubheader, ListItemText, Paper, Popper, MenuItem, MenuList, ClickAwayListener, Grow } from '@material-ui/core';
// Components
// import HowItWorks from './HowItWorks'
import ContactModel from './ContactModel'
// Media
import Logo from '../assets/images/logo/logo.svg';
import MenuIcon from '../assets/images/menu-top-icon.svg'
import HomeIcon from '@material-ui/icons/Home';
import WorkIcon from '@material-ui/icons/Work';
import HelpIcon from '@material-ui/icons/Help';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
// import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { faInstagram, faFacebookF, faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles((theme) => ({
    list: {
        width: 300,
    },
    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const Header = () => {
    const classes = useStyles();
    let location = useLocation().pathname;
    const [urlHomepage, setUrlHomepage] = useState('')
    const [urlListJobs, setUrlListJobs] = useState('')
    const [urlHowItWorks, setUrlHowItWorks] = useState('')
    const [urlBlog, setUrlBlog] = useState('')
    // const [open, setOpen] = useState(false)
    const [openMenu, setOpenMenu] = useState(false)
    const [openHelp, setOpenHelp] = useState(false)

    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpenMenu((prevOpenMenu) => !prevOpenMenu);
    };

    const handleCloseMenu = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenMenu(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenMenu(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(openMenu);
    useEffect(() => {
        if (prevOpen.current === true && openMenu === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = openMenu;
    }, [openMenu]);


    useEffect(() => {
        checkURL()
        // eslint-disable-next-line
    }, [])

    // const handleOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    const handleOpenHelp = () => {
        setOpenHelp(true);
    };

    const handleCloseHelp = () => {
        setOpenHelp(false);
    };

    const closeSideBar = () => {
        setState({ ...state, 'left': false });
    }

    const checkURL = () => {
        if (location === "/") {
            setUrlHomepage('active');
        }
        if (location === "/list-jobs") {
            setUrlListJobs('active');
        }
        if (location === "/blog") {
            setUrlBlog('active');
        }
        if (location === "/how-it-works/creator" || location === "/how-it-works/worker") {
            setUrlHowItWorks('active');
        }
    }

    const [state, setState] = useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };;
    return (
        <div className="header sticky">
            <Container>
                <Navbar expand="lg" className="pt-3 pb-3">
                    {['left'].map((anchor, index) => (
                        <div key={index}>
                            <img src={MenuIcon} alt="Menu" className="mobile-view menu-icon" onClick={toggleDrawer(anchor, true)} />
                            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                                <div
                                    className={clsx(classes.list, {
                                        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
                                    })}
                                    role="presentation"
                                    onKeyDown={toggleDrawer(anchor, false)}
                                >
                                    <List
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                        subheader={
                                            <ListSubheader component="div" className="text-center" id="nested-list-subheader">
                                                <img src={Logo} alt="Logo" className="w-75 mt-3 mb-3" />
                                            </ListSubheader>
                                        }
                                        className={classes.root}
                                    >
                                        <Divider />
                                        <div>
                                            <a href="/">
                                                <ListItem button>
                                                    <ListItemIcon><HomeIcon /></ListItemIcon>
                                                    <ListItemText primary="Home" />
                                                </ListItem>
                                            </a>
                                            <a href="/list-jobs">
                                                <ListItem button>
                                                    <ListItemIcon><WorkIcon /></ListItemIcon>
                                                    <ListItemText primary="Available Shifts" />
                                                </ListItem>
                                            </a>
                                            <a href="/how-it-works/creator">
                                                <ListItem button>
                                                    <ListItemIcon><HelpIcon /></ListItemIcon>
                                                    <ListItemText primary="How It Works - Creator" />
                                                </ListItem>
                                            </a>
                                            <a href="/how-it-works/worker">
                                                <ListItem button>
                                                    <ListItemIcon><HelpIcon /></ListItemIcon>
                                                    <ListItemText primary="How It Works - Worker" />
                                                </ListItem>
                                            </a>
                                            <a href="/blog">
                                                <ListItem button>
                                                    <ListItemIcon><AssignmentIndIcon /></ListItemIcon>
                                                    <ListItemText primary="Get Hyred" />
                                                </ListItem>
                                            </a>
                                            <a href="#!" onClick={e => {
                                                e.preventDefault()
                                                handleOpenHelp()
                                            }}>
                                                <ListItem button>
                                                    <ListItemIcon><QuestionAnswerIcon /></ListItemIcon>
                                                    <ListItemText primary="Contact Us" />
                                                </ListItem>
                                            </a>
                                            {/* <a href="#!">
                                                <ListItem button>
                                                    <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                                    <ListItemText primary="My Account" />
                                                </ListItem>
                                            </a> */}
                                        </div>
                                    </List>
                                    <Divider />
                                    {/* <List className="mt-3 text-center">
                                        <a href="#!" rel="noreferrer" target="_blank"><Button className="btn-social" variant="outlined"><FontAwesomeIcon icon={faInstagram} /></Button></a>
                                        <a href="#!" rel="noreferrer" target="_blank"><Button className="btn-social" variant="outlined"><FontAwesomeIcon icon={faFacebookF} /></Button></a>
                                        <a href="#!" rel="noreferrer" target="_blank"><Button className="btn-social" variant="outlined"><FontAwesomeIcon icon={faLinkedinIn} /></Button></a>
                                        <a href="#!" rel="noreferrer" target="_blank"><Button className="btn-social" variant="outlined"><FontAwesomeIcon icon={faTwitter} /></Button></a>
                                    </List> */}
                                </div>
                            </Drawer>
                        </div>
                    ))}
                    <Navbar.Brand href="/">
                        <div className="web-view">
                            <img src={Logo} alt="Logo" className="h-75" />
                        </div>
                        <div className="mobile-view text-center">
                            <img src={Logo} alt="Logo" className="h-75" />
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Nav.Link href="/" className={urlHomepage}>Home</Nav.Link>
                            <Nav.Link href="/list-jobs" className={urlListJobs}>Available Shifts</Nav.Link>
                            {/* <Nav.Link onClick={handleOpen}>How It Works</Nav.Link> */}
                            <Nav.Link
                                className={urlHowItWorks}
                                ref={anchorRef}
                                aria-controls={openMenu ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={handleToggle}
                            >
                                How It Works
                            </Nav.Link>
                            <Popper open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'top left' : 'bottom left' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleCloseMenu}>
                                                <MenuList autoFocusItem={openMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                    <a href="/how-it-works/creator">
                                                        <MenuItem onClick={handleCloseMenu}>Creator</MenuItem>
                                                    </a>
                                                    <a href="/how-it-works/worker">
                                                        <MenuItem onClick={handleCloseMenu}>Worker</MenuItem>
                                                    </a>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                            <Nav.Link href="/blog" className={urlBlog}>Get Hyred</Nav.Link>
                            <Nav.Link onClick={handleOpenHelp}>Contact Us</Nav.Link>
                            {/* <Nav.Link href="#!/my-account">My Account</Nav.Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
            {/* <HowItWorks open={open} close={handleClose} side={closeSideBar} /> */}
            <ContactModel open={openHelp} close={handleCloseHelp} side={closeSideBar} />
        </div>
    )
}

export default Header
