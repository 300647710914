// Core
import React from 'react'
// Library
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
// Components
import Header from './components/Header'
import Footer from './components/Footer'
// Pages: Pages
import Homepage from './pages/Homepage'
import AllCategories from './pages/AllCategories'
import ListJobs from './pages/ListJobs'
import Support from './pages/Support'
import TermsAndConditions from './pages/TermsAndConditions'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Creator from './pages/how_it_works/Creator'
import Worker from './pages/how_it_works/Worker'

// Pages: Blog Pages
import Blogs from './pages/blogs/Blogs'
import BlogSingle from './pages/blogs/BlogSingle'
import BlogCategory from './pages/blogs/BlogCategory'
import BlogSearch from './pages/blogs/BlogSearch'

const Layout = () => {
    return (
        <div>
            <Router>
                <Header />
                <Switch>
                    {/* Pages */}
                    <Route exact path='/' component={Homepage} />
                    <Route exact path='/all-categories' component={AllCategories} />
                    <Route exact path='/list-jobs' component={ListJobs} />
                    <Route exact path='/support' component={Support} />
                    <Route exact path='/terms-and-conditions' component={TermsAndConditions} />
                    <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                    <Route exact path='/how-it-works/creator' component={Creator} />
                    <Route exact path='/how-it-works/worker' component={Worker} />

                    {/* Blog Pages */}
                    <Route exact path='/blog' component={Blogs} />
                    <Route exact path='/blog/:slug' component={BlogSingle} />
                    <Route exact path='/category/' > <Redirect to="/blog" /> </Route>
                    <Route exact path='/category/:slug' component={BlogCategory} />
                    <Route exact path='/blog/search/:slug' component={BlogSearch} />
                </Switch>
                <Footer />
            </Router>
        </div>
    )
}

export default Layout
